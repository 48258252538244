import { Container, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  bg: {
    backgroundColor: '#1b0921',
    padding: '1.5rem',
  },

  reserved: {
    color: '#fff',
    fontSize: '0.8rem',
  },

  link: {
    fontSize: 12,
    color: '#fff',
  },
}))

function Footer() {
  const classes = useStyles()

  return (
    <div className={classes.bg}>
      <Container>
        <Grid container justifyContent={'space-between'}>
          <span className={classes.reserved}>© 2020 Betting Api</span>

          <div>
            <Link to={'/policy'} className={classes.link}>
              Private Policy
            </Link>
          </div>
        </Grid>
      </Container>
    </div>
  );
}

export default Footer
