import api from './apiApps'
import { actions } from './reducer'

export default {
  getAll() {
    return async (dispatch) => {
      const data = await api.getApps()
      await dispatch(actions.setList(data))
    }
  },
  createApp({ name }: { name: string }) {
    return async (dispatch) => {
      await api.createApp({ name })
      dispatch(this.getAll())
    }
  },
}
