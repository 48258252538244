import { Grid, Typography, css } from '@mui/material'
import { useAppSelector } from '@reducers/store'
import _ from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

function WorkspacePlan() {
  const { plans, workspace } = useAppSelector((state) => ({
    plans: state.plans.list,
    workspace: state.workspace,
  }))

  let { id, plan, plan_expired_at } = workspace || {}

  const epirationDate = useMemo(() => {
    if (!plan_expired_at) return null
    const expireMoment = moment(plan_expired_at)
    return expireMoment.format('L')
  }, [plan_expired_at])

  const activePlan = useMemo(() => {
    if (!id) return null
    if (!plan) {
      plan = 'default'
    }

    const item = _.find(plans, (item) => item.key === plan)

    return item
  }, [plans, workspace])

  const planPreview = activePlan?.name || '-'

  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/console/plan')
  }

  return (
    <div>
      <Grid container alignItems={'center'}>
        <Grid
          flexGrow={1}
          css={css`
            margin-right: 30px;
          `}
        >
          <Typography>Your plan:</Typography>
          <Typography fontWeight={'bold'}>{planPreview}</Typography>
        </Grid>

        <div>
          <Typography
            onClick={handleClick}
            css={css`
              font-size: 12px;
              color: #e8b717;
              font-weight: bold;
              cursor: pointer;
              padding: 7px 3px;
            `}
          >
            change
          </Typography>
        </div>
      </Grid>

      <div>
        {epirationDate && (
          <Typography
            fontSize={12}
            css={css`
              color: #958f8f;
            `}
          >
            Expires: {epirationDate}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default WorkspacePlan
