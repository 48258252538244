import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactNode } from 'react'

const useStyles = makeStyles({
  supportTitle: {
    fontSize: '1rem',
    paddingBottom: 15,
  },
  supportContainer: {
    marginTop: 30,
  },
})

function SupportTableContainer(props: { children: ReactNode }) {
  const classes = useStyles()
  const { children } = props

  return (
    <div className={classes.supportContainer}>
      <Typography variant={'h2'} className={classes.supportTitle}>
        Api Support Table
      </Typography>
      {children}
    </div>
  )
}

export default SupportTableContainer
