import { getAuth } from 'firebase/auth'
import apiClient from '../../libs/apiClient'
import { DispatcherResponse } from '../../typings/ReduxDispatch'
import api from './apiAuth'
import { actions } from './reducer'

const getFirebaseToken = async (): Promise<string | null> => {
  try {
    const { currentUser } = getAuth()
    if (!currentUser) {
      return null
    }

    const token = await currentUser.getIdToken()
    if (!token) {
      return null
    }

    return token
  } catch (e) {
    console.log('ERR [handleFirebaseLogin]:', e)
  }

  return null
}

class Dispatcher {
  setToken(authData) {
    return (dispatch) => {
      dispatch(actions.setToken(authData))
      dispatch(this.useCredentials())
    }
  }

  useCredentials() {
    return (dispatch, getState) => {
      let { token } = getState().auth

      // if (!token) {
      //   token = localStorage.getItem('token') || undefined
      //   dispatch(actions.setToken({ token }))
      // }
      if (!token) return null
      apiClient.setToken(token)
    }
  }

  loginWithFirebaseToken(): DispatcherResponse {
    return async (dispatch) => {
      const firebaseToken = await getFirebaseToken()
      if (!firebaseToken) return null
      const data = await api.loginWithFirebaseToken(firebaseToken)

      dispatch(this.setToken(data))
      return data
    }
  }

  logout() {
    return async (dispatch) => {
      dispatch(actions.setToken({ token: null }))
      apiClient.removeToken()
      localStorage.removeItem('token')
    }
  }

  loginForAdmin(authData) {
    return (dispatch) => {
      dispatch(this.setToken(authData))
    }
  }
}

const AuthDispatcher = new Dispatcher()
export default AuthDispatcher
