import apiClient from '../../libs/apiClient'

type AuthData = { token: string }

export default {
  async loginWithFirebaseToken(token: string): Promise<AuthData> {
    const result = await apiClient.post(`/auth/firebase`, { accessToken: token })
    return result.data
  },
  async me(): Promise<AuthData> {
    const result = await apiClient.get(`/me`)

    return result.data
  },
}
