import { Container } from '@mui/material'
import React from 'react'
import HeaderBar from '../../components/HeaderBar'
import Policy from './Policy'

class PolicyPage extends React.PureComponent {
  render() {
    return (
      <div>
        <HeaderBar />
        <Container>
          <Policy />
        </Container>
      </div>
    )
  }
}

export default PolicyPage
