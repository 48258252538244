import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import createGenerateClassName from '@mui/styles/createGenerateClassName'
import jssPreset from '@mui/styles/jssPreset'
import { store, persistor } from '@reducers/store'
import { create } from 'jss'
import React from 'react'
import './App.css'
import { JssProvider } from 'react-jss'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import AppLauncher from './AppLauncher'
import AppRouter from './AppRouter'
import theme from './theme'

const generateClassName = createGenerateClassName()
const jss = create(jssPreset())

export default function () {
  return (
    <div id={'scroller'}>
      {/* <ErrorBoundary> */}
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                  <AppLauncher>
                    {/* <AuthGuard /> */}
                    {/* <ModalRoot /> */}
                    {/* <CssBaseline /> */}

                    <AppRouter />
                  </AppLauncher>
                </BrowserRouter>
              </PersistGate>
            </Provider>
          </ThemeProvider>
        </StyledEngineProvider>
      </JssProvider>
      {/* </ErrorBoundary> */}
    </div>
  )
}
