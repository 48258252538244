import { css } from '@emotion/react'
import { Divider, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import CodeExample from './app/CodeExample'

const useStyles = makeStyles({
  secretKey: {
    fontSize: 12,
    backgroundColor: '#cdcce4',
    padding: '5px 5px',
    borderRadius: 5,
  },

  appLabel: {
    fontSize: 16,
    fontWeight: 300,
  },
})

interface Props {
  app: App
}
function App(props: Props) {
  const { app } = props
  const classes = useStyles()
  // const { app_id } = useParams<{ app_id: string }>()
  // const { app } = useSelector((state: ReduxState) => ({
  //   app: _.find(state.apps.list, { id: app_id }),
  // }))

  if (!app) return null
  const { name, secret_key } = app

  return (
    <Grid
      css={css`
        padding: 20px 0;
      `}
    >
      {/* <Typography */}
      {/*  variant={'h2'} */}
      {/*  css={css` */}
      {/*    margin-bottom: 30px; */}
      {/*  `} */}
      {/* > */}
      {/*  <span className={classes.appLabel}>app:</span> {name} */}
      {/* </Typography> */}
      <div>
        <Typography display={'inline'} fontWeight={'bold'}>
          Secret key:
        </Typography>{' '}
        <span className={classes.secretKey}>{secret_key}</span>
      </div>
      <div
        css={css`
          margin-top: 20px;
        `}
      >
        Set this secret to request
        <span>
          <b> authorization </b>
        </span>
        header.
      </div>

      <Divider
        css={css`
          margin-top: 30px;
          margin-bottom: 30px;
        `}
      />

      <CodeExample secret_key={secret_key} />
    </Grid>
  )
}

export default App
