import React from 'react'
import { Helmet } from 'react-helmet'
import HeaderBar from '../../components/HeaderBar'
import ApiClients from './ApiClients'
import DocsList from './DocsList'

class Docs extends React.PureComponent {
  render() {
    return (
      <div>
        <Helmet>
          <title>Betting Api Docs. Bookmakers api docs.</title>
          <meta name="description" content="Documentation for several bookmakers. Live and prematch docs." />
        </Helmet>
        <HeaderBar />
        <DocsList />
        {/* <Footer /> */}
        <ApiClients />
      </div>
    )
  }
}

export default Docs
