import { Container, Typography, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import config from '../../../config'

const useStyles = makeStyles(theme => ({
  bg: {
    backgroundColor: '#fff',
    padding: '1.5rem',
  },

  reserved: {
    color: '#fff',
    fontSize: '0.8rem',
  },
}))

const { email } = config

function Contacts() {
  const classes = useStyles()

  return (
    <div className={classes.bg} id={'contacts'}>
      <Container>
        <Typography variant={'h2'} align={'center'}>
          Contacts
        </Typography>
        <Typography variant={'body1'} align={'center'}>
          If you have any questions, please write to this email:
          <Link href={`mailto:${email}`}> {email} </Link>
        </Typography>
      </Container>
    </div>
  )
}

export default Contacts
