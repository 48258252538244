import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import makeStyles from '@mui/styles/makeStyles'
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import LoginButton from './LoginButton'

const useStyles = makeStyles({
  icon: {
    color: '#fff',
    fontSize: '1rem',
  },

  button: {
    background: '#ff3f44',

    '&:hover': {
      backgroundColor: '#ff7667',
    },
  },
})

const logoutFirebaseSafe = async () => {
  try {
    const auth = getAuth()
    await signOut(auth)
  } catch (e) {
    console.log('ERROR [logoutGoogle]', e)
  }
}

const auth = getAuth()
const provider = new GoogleAuthProvider()

function ButtonGoogle() {
  const classes = useStyles()

  const actionLogin = useAsyncHandler(async () => {
    await logoutFirebaseSafe()

    try {
      await signInWithPopup(auth, provider)
    } catch (e) {
      // if (e.code === 'auth/popup-blocked') {
      //   // this case always happens for mobiles
      //   await signInWithRedirect(auth, provider)
      //   return
      // }
    }
  })

  return (
    <LoginButton
      className={classes.button}
      title={'Login With Google'}
      icon={<FontAwesomeIcon icon={faGooglePlusG} className={classes.icon} />}
      onClick={actionLogin.execute}
    />
  )
}

export default ButtonGoogle
