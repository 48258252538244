import { css } from '@emotion/react'
import { Button, Divider, Grid, Typography } from '@mui/material'
import PlanDispatcher from '@reducers/plans/dispatcher'
import { useAppDispatch } from '@reducers/store'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useNavigate } from 'react-router-dom'
import Loader from 'src/components/Loader'
import PlanView from './PlanView'

interface Props {
  plan: any
  isSelected: boolean
}
function Plan(props: Props) {
  const { plan, isSelected } = props
  const { key } = plan
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const actionPurchaseRequest = useAsyncHandler(async () => {
    await dispatch(PlanDispatcher.purchase({ key }))
    navigate('/console/payment')
  })

  const handleClickChoose = () => {
    actionPurchaseRequest.execute()
  }

  return (
    <PlanView plan={plan} isSelected={isSelected}>
      <Divider />

      <Grid
        container
        justifyContent={'center'}
        css={css`
          padding-top: 20px;
        `}
      >
        {isSelected ? (
          <Typography
            color={'#558ab9'}
            fontWeight={'bold'}
            fontSize={'12px'}
            css={css`
              margin-top: 10px;
            `}
          >
            Your current plan
          </Typography>
        ) : actionPurchaseRequest.isLoading ? (
          <Loader />
        ) : (
          <Button variant={'contained'} fullWidth onClick={handleClickChoose}>
            Choose
          </Button>
        )}
      </Grid>
    </PlanView>
  )
}

export default Plan
