import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {}

const initialState: State = {}

const slice = createSlice({
  name: 'ME',
  initialState,
  extraReducers: {
    [appTypes.RESET]: () => initialState,
  },
  reducers: {
    set(state, action: PayloadAction<any>) {
      return action.payload
    },
  },
})

export const { reducer, actions } = slice
export default reducer
