import { ReduxState } from '@reducers/index'
import { useSelector } from 'react-redux'

function useIsLoggedIn(): boolean {
  const { token } = useSelector((state: ReduxState) => ({ token: state.auth.token }))

  const isLoggedIn = !!token

  return isLoggedIn
}

export default useIsLoggedIn
