import { css } from '@emotion/react'
import { Container, Typography, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import xBetLogo from '../../../assets/services/1xbet.svg'
import betwayLogo from '../../../assets/services/betway.jpg'
import fonbetLogo from '../../../assets/services/fonbet.jpg'
import ggBetLogo from '../../../assets/services/ggbet.svg'
import leonbetsLogo from '../../../assets/services/leonbets.jpg'
import marathonbetLogo from '../../../assets/services/marathonbet.png'
import myscoreLogo from '../../../assets/services/myscore2.jpg'
import parimatchLogo from '../../../assets/services/parimatch.png'
import sbobetLogo from '../../../assets/services/sbobet.png'
import Service from './Service'

const useStyles = makeStyles({
  bg: {
    paddingBottom: '2rem',
  },

  container: {
    maxWidth: 450,
  },
})

function OurServices() {
  const classes = useStyles()

  return (
    <div
      className={classes.bg}
      css={css`
        padding-top: 30px;
      `}
    >
      <Container className={classes.container}>
        <Typography
          variant={'h2'}
          align={'center'}
          css={css`
            margin-bottom: 20px;
          `}
        >
          Betting api services
        </Typography>

        <Service image={xBetLogo} title={'1xBet Api'} href={'/1xbet'} />

        <Divider />

        <Service image={sbobetLogo} title={'Sbobet Api'} href={'/sbobet'} />

        <Divider />

        <Service image={betwayLogo} title={'BetWay Api'} href={'/betway'} />

        <Divider />

        <Service image={marathonbetLogo} title={'MarathonBet Api'} href={'/marathonbet'} />

        <Divider />

        <Service image={parimatchLogo} title={'PariMatch Api'} href={'/parimatch'} />

        <Divider />

        <Service image={leonbetsLogo} title={'LeonBets Api'} href={'/leonbets'} />

        <Divider />

        <Service image={ggBetLogo} title={'GGBet Api'} href={'/ggbet'} />

        <Divider />

        <Service image={fonbetLogo} title={'FonBet Api'} href={'/fonbet'} />

        {/*<Divider />*/}

        {/*<Service image={myscoreLogo} title={'FlashScore Api'} href={''} />*/}
      </Container>
    </div>
  )
}

export default OurServices
