import { css } from '@emotion/react'
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  children: ReactElement
}
function LinkAuto(props: Props & Parameters<typeof Link>[0]): ReactElement {
  const { to, children } = props

  if (!to) return children

  return (
    <Link
      {...props}
      css={css`
        text-decoration: none;
      `}
    >
      {children}
    </Link>
  )
}

export default LinkAuto
