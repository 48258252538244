import { combineReducers } from 'redux'
import apps from './apps/reducer'
import auth from './auth/reducer'
import me from './me/reducer'
import plans from './plans/reducer'
import wallet from './wallet/reducer'
import workspace from './workspace/reducer'

export const reducer = combineReducers({
  auth,
  apps,
  me,
  wallet,
  plans,
  workspace,
})
