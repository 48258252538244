import { css } from '@emotion/react'
import { Payment } from '@reducers/wallet/api'
import React from 'react'
import config from '../../config'

interface Props {
  payment: Pick<Payment, 'tx_id'>
}
function PaymentUI(props: Props) {
  const { payment } = props
  const { tx_id } = payment || {}
  if (!tx_id) return null

  return (
    <iframe
      title={'Payment'}
      src={`${config.paymentURL}/${tx_id}`}
      css={css`
        width: 100%;
        height: 100%;
        background: #fff;
      `}
    />
  )
}

export default PaymentUI
