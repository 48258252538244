import { css } from '@emotion/react'
import { Grid, Modal, TextField, Paper, Button } from '@mui/material'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import makeStyles from '@mui/styles/makeStyles'
import { useAppDispatch } from '@reducers/store'
import React, { useState } from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import Loader from '../../../components/Loader'
import AppDispatcher from '../../../reducers/apps/dispatcher'

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  button: {
    marginTop: 30,
  },

  input: {
    marginTop: 10,
  },
})

interface Props {
  isVisible: boolean
  onClose(): any
}

function ModalCreateApp(props: Props) {
  const { isVisible, onClose } = props
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const [name, setName] = useState('')

  const nameFormatted = name.trim()

  const actionCreate = useAsyncHandler(async () => {
    await dispatch(AppDispatcher.createApp({ name: nameFormatted }))
    onClose()
  })

  const handlePress = async () => {
    actionCreate.execute()
  }

  const isValid = !!nameFormatted
  const isButtonDisabled = !isValid || actionCreate.isLoading

  const handleChangeText: OutlinedInputProps['onChange'] = (e) => {
    let textNext = e.target.value
    textNext = textNext.replace(/[^\w\s]/, '')
    textNext = textNext.slice(0, 20)
    setName(textNext)
  }

  return (
    <Modal open={isVisible} onClose={onClose} className={classes.modal}>
      <Paper
        css={css`
          width: 280px;
          padding: 10px 20px 20px;
        `}
      >
        <Grid container justifyContent={'center'} direction={'column'}>
          <TextField
            value={name}
            onChange={handleChangeText}
            placeholder={'App Name'}
            className={classes.input}
            variant="outlined"
          />

          {actionCreate.isLoading ? (
            <Loader />
          ) : (
            <Button
              variant="contained"
              color={'primary'}
              onClick={handlePress}
              disabled={isButtonDisabled}
              className={classes.button}
            >
              Create
            </Button>
          )}
        </Grid>
      </Paper>
    </Modal>
  )
}

export default ModalCreateApp
