import WalletDispatcher from '../wallet/dispatcher'
import api from './api'
import { actions } from './reducer'

class Dispatcher {
  getAll() {
    return async (dispatch) => {
      const data = await api.getPlans()
      await dispatch(actions.setList(data))
    }
  }

  purchase({ key }: { key: string }) {
    return async (dispatch) => {
      const data = await api.purchase({ key })
      await dispatch(WalletDispatcher.getPayment(data.payment_id))
    }
  }
}

const PlanDispatcher = new Dispatcher()
export default PlanDispatcher
