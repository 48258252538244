import { css } from '@emotion/react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Grid } from '@mui/material'
import React, { useState } from 'react'
import ModalCreateApp from './ModalCreateApp'

function AppCreateButton() {
  const [isModalVisible, setModalVisibility] = useState(false)

  const handlePress = () => {
    setModalVisibility(true)
  }

  const handleModalClose = () => {
    setModalVisibility(false)
  }

  return (
    <div>
      <Grid
        container
        alignItems={'center'}
        direction={'row'}
        onClick={handlePress}
        css={css`
          padding: 5px 10px;
          cursor: pointer;
          border-radius: 8px;

          &:hover {
            background-color: #c5cfe6;
          }
        `}
      >
        <FontAwesomeIcon
          icon={faPlusCircle}
          css={css`
            color: #323232;
            font-size: 24px;
            margin-right: 10px;
          `}
        />
        <Typography
          component={'span'}
          css={css`
            font-size: 20px;
            user-select: none;
          `}
        >
          Create App
        </Typography>
      </Grid>
      <ModalCreateApp isVisible={isModalVisible} onClose={handleModalClose} />
    </div>
  )
}

export default AppCreateButton
