import apiClient from '../../libs/apiClient'

export default {
  async getPlans(): Promise<Plan> {
    const result = await apiClient.get(`/plan/list`)
    return result.data
  },
  async purchase(params: { key: string; year?: boolean }): Promise<{ payment_id: string }> {
    const result = await apiClient.post(`/plan/purchase`, params)
    return result.data
  },
}
