import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  // [key: string]: ReduxStateApp
  list: any[]
}


const initialState: State = {
  list: [],
}

const slice = createSlice({
  name: 'Plans',
  initialState,
  extraReducers: {
    [appTypes.RESET]: () => initialState,
  },
  reducers: {
    setList(state, action: PayloadAction<any[]>) {
      const list = action.payload
      state.list = list
    },
  },
})

export const { reducer, actions } = slice
export default reducer
