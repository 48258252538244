import { css } from '@emotion/react'
import { Typography } from '@mui/material'
import { highlight, languages } from 'prismjs'
import React, { useMemo } from 'react'

import 'prismjs/themes/prism.css'
import 'prismjs/components/prism-javascript'

interface Props {
  secret_key: string
}
function CodeExample(props: Props) {
  const { secret_key } = props
  const codePreviewHTML = useMemo(() => {
    if (!secret_key) return null

    const code = `
      fetch(
        'https://api.betting-api.com/1xbet/football/line/all', 
        { 
          headers: { authorization: '${secret_key}'  }
        }
      ).then(response => response.json())
      .then(data=> console.log('result', data))

    `

    let html = highlight(code, languages.js)

    return html
  }, [secret_key])

  if (!secret_key) return null

  return (
    <div>
      <Typography
        css={css`
          margin-bottom: 10px;
          font-weight: bold;
        `}
      >
        JS code snippet that you can run in your browser console:
      </Typography>
      <div
        css={css`
          background: #fff;
          padding: 10px;
        `}
      >
        <div dangerouslySetInnerHTML={{ __html: codePreviewHTML }} />
      </div>
    </div>
  )
}

export default CodeExample
