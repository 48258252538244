import { css } from '@emotion/react'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useIsLoggedIn from '../hooks/useIsLoggedIn'
import LoginModal from '../pages/login/components/LoginModal'

function ConsoleButton() {
  const [isModalVisible, setModalVisibility] = useState(false)

  const navigate = useNavigate()
  const isLoggedIn = useIsLoggedIn()

  const handleClick = () => {
    if (isLoggedIn) return navigate('/console')
    setModalVisibility(true)
  }

  const handleModalClose = () => {
    setModalVisibility(false)
  }

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        css={css({
          marginLeft: '2rem',
          backgroundColor: '#fff',
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          borderRadius: 6,
          color: '#323232',
          fontWeight: 'bold',

          '&:hover': {
            backgroundColor: '#d6d0d0',
          },
          '&:active': {
            backgroundColor: '#d6d0d0',
            border: 'none',
          },
        })}
        onClick={handleClick}
      >
        Admin
      </Button>
      <LoginModal isVisible={isModalVisible} onClose={handleModalClose} />
    </div>
  )
}

export default ConsoleButton
