const services = [
  { name: '1xBet', docs_href: 'http://docs.betting-api.com/1xbet/index.html' },
  { name: 'BetWay', docs_href: 'http://docs.betting-api.com/betway/index.html' },
  { name: 'Sbobet', docs_href: 'http://docs.betting-api.com/sbobet/index.html' },
  { name: 'MarathonBet', docs_href: 'http://docs.betting-api.com/marathonbet/index.html' },
  { name: 'PariMatch', docs_href: 'http://docs.betting-api.com/parimatch/index.html' },
  { name: 'Fonbet', docs_href: 'http://docs.betting-api.com/fonbet/index.html' },
  { name: 'LeonBets', docs_href: 'http://docs.betting-api.com/leonbets/index.html' },
  { name: 'GGBet', docs_href: 'http://docs.betting-api.com/ggbet/index.html' },
  { name: 'FlashScore', docs_href: 'http://docs.betting-api.com/flashscore/index.html' },
]

export default services
