import { css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import PlanDispatcher from '@reducers/plans/dispatcher'
import { useAppDispatch, useAppSelector } from '@reducers/store'
import WorkspaceDispatcher from '@reducers/workspace/dispatcher'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import RendererStatusSplit from 'react-renderer-status-split'
import config from '../../config'
import Plan from './plan/Plan'

function PlanPage() {
  const dispatch = useAppDispatch()
  const actionFetch = useAsyncFetch(async () => {
    await dispatch(PlanDispatcher.getAll())
    await dispatch(WorkspaceDispatcher.getWorkspace())
  })
  const { plans, workspace } = useAppSelector((state) => ({
    plans: state.plans.list,
    workspace: state.workspace,
  }))

  const activePlan = useMemo(() => {
    let { id, plan } = workspace
    if (!id) return null
    if (!plan) {
      plan = 'default'
    }

    const item = _.find(plans, (item) => item.key === plan)

    return item
  }, [plans, workspace])

  return (
    <div>
      <div>
        <Typography
          variant={'h1'}
          css={css`
            margin-bottom: 20px;
            margin-top: 50px;
          `}
        >
          Available plans
        </Typography>
      </div>

      <div>
        <RendererStatusSplit
          statuses={actionFetch}
          render={() => (
            <div>
              <Grid container gap={'30px'}>
                {_.map(plans, (plan) => (
                  <Plan plan={plan} isSelected={plan.key === activePlan?.key} />
                ))}
              </Grid>
            </div>
          )}
        />
      </div>

      <div
        css={css`
          margin: 20px 0;
        `}
      >
        <Typography
          css={css`
            color: #6e6565;
          `}
        >
          Note: At the moment we support only crypto payments.
        </Typography>

        <Typography
          css={css`
            color: #6e6565;
          `}
        >
          If you have any issue changing your plan, please contact {config.email}
        </Typography>
      </div>
    </div>
  )
}

export default PlanPage
