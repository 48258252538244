import { Modal, Paper, Typography, Divider } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import React from 'react'
import ButtonFacebook from './loginButtons/ButtonFacebook'
import ButtonGoogle from './loginButtons/ButtonGoogle'

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    width: 300,
    padding: 10,
  },

  divider: {
    margin: '1rem 0',
  },
  title: {
    fontWeight: 800,
  },
}

interface Props {
  isVisible: boolean
  onClose(): any
}
interface State {}

class LoginModal extends React.PureComponent<Props & WithStyles<typeof styles>, State> {
  state: State = {}

  render() {
    const { classes, isVisible, onClose } = this.props

    return (
      <Modal open={isVisible} onClose={onClose} className={classes.modal}>
        <Paper className={classes.paper}>
          <div>
            <Typography component={'h2'} className={classes.title} align={'center'}>
              Sign In
            </Typography>

            <Divider className={classes.divider} />

            <ButtonFacebook />
            <ButtonGoogle />
          </div>
        </Paper>
      </Modal>
    )
  }
}

export default withStyles(styles)(LoginModal)
