import warning from 'warning'

const { API_URL } = process.env

warning(API_URL, 'API_URL is required')

const config = {
  api_url: API_URL,
  email: 'support@betting-api.com',
  web_url: 'https://betting-api.com',

  paymentURL: 'https://payment.octoex.com', // without '/'
  // paymentURL: 'http://localhost:5577', // without '/'
}

export default config
