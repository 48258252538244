import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { Helmet } from 'react-helmet'
import logo from '../../assets/services/1xbet.svg'
import HeaderBar from '../../components/HeaderBar'
import DocsLink from './components/DocsLink'
import SupportTable from './components/SupportTable'
import SupportTableContainer from './components/SupportTableContainer'

const useStyles = makeStyles({
  logo: {
    width: 160,
    height: 160,
    objectFit: 'contain',
  },

  page: {
    maxWidth: 1000,
    margin: 'auto',
    paddingBottom: 50,
  },

  main: {
    marginTop: 50,
  },

  leftHolder: {
    paddingRight: 50,
  },

  title: {
    marginBottom: 10,
  },
})

function XBet() {
  const classes = useStyles()

  return (
    <div>
      <Helmet>
        <title>1xBet API. Live and PreMatch odds.</title>
        <meta name="description" content="1xBet odds api. 1xBet live odds and prematch odds. Bets from 1xBet." />
      </Helmet>
      <HeaderBar />
      <div className={classes.page}>
        <Grid container>
          <Grid item className={classes.leftHolder}>
            <div>
              <img src={logo} alt="1xBet logo" className={classes.logo} />
            </div>

            <DocsLink title={'1xBet docs'} href={'http://docs.betting-api.com/1xbet/index.html'} />
          </Grid>

          <Grid item className={classes.main}>
            <Typography variant={'h1'} className={classes.title}>
              1xBet API
            </Typography>
            <Typography>We provide live and pre match odds for some sport events.</Typography>

            <SupportTableContainer>
              <SupportTable football={['live', 'line', 'events']} />
            </SupportTableContainer>
          </Grid>
        </Grid>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default XBet
