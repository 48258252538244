import axios, { AxiosInstance } from 'axios'
import _ from 'lodash'
import packageJson from '../../package.json'

const defaultOptions = {
  timeout: 30000,
  headers: {
    'app-version': packageJson.version,
  },
}

interface AxiosCustomInstance extends AxiosInstance {
  setToken(token: string): void
  removeToken(): void
}

export default (options): AxiosCustomInstance => {
  const instance = axios.create(_.defaultsDeep(options, defaultOptions)) as AxiosCustomInstance

  if (process.env.NODE_ENV !== 'production') {
    patchMethod(instance, 'get')
    patchMethod(instance, 'post')
    patchMethod(instance, 'delete')
  }

  instance.setToken = (token: string) => {
    instance.defaults.headers.common['Authorization'] = token
  }

  instance.removeToken = () => {
    delete instance.defaults.headers.common['Authorization']
  }

  return instance
}

function patchMethod(instance, methodName) {
  const originName = `${methodName}Original`
  instance[originName] = instance[methodName]
  instance[methodName] = async (...args) => {
    try {
      return await instance[originName](...args)
    } catch (e) {
      const logArgs = ['[ERROR] API']
      if (e.response) {
        logArgs.push(e.response.status)
        logArgs.push(e.response.data)
        logArgs.push(e.response)
      } else logArgs.push(e.message)
      console.log(...logArgs)
      throw e
    }
  }
}
