import AuthDispatcher from '../auth/dispatcher'
import MeDispatcher from '../me/dispatcher'

export default {
  install() {
    return async (dispatch, getState) => {
      await dispatch(AuthDispatcher.useCredentials())

      const { token } = getState().auth
      if (token) {
        await dispatch(this.userInstall())
      }
    }
  },
  userInstall() {
    return async (dispatch) => {
      await dispatch(MeDispatcher.getMe())
    }
  },
}
