import api from './api'
import { actions } from './reducer'

class Dispatcher {
  getWorkspace() {
    return async (dispatch) => {
      const workspace = await api.getWorkspace()
      if (workspace) {
        dispatch(actions.set(workspace))
      }
    }
  }
}

const WorkspaceDispatcher = new Dispatcher()
export default WorkspaceDispatcher
