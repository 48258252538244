import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

const useStyles = makeStyles({
  logo: {
    width: 50,
    height: 50,
    objectFit: 'contain',
    objectPosition: 'center',
    marginTop: 3,
  },

  detailsContainer: {
    // marginLeft: 20,
  },

  container: {
    marginBottom: 10,
    paddingLeft: 60,
  },

  link: {
    // marginLeft: 30,
  },
})

interface Props {
  // image: string
  title: string
  link: string
}

function DocsItem(props: Props) {
  const classes = useStyles()
  const { image, title, link } = props

  return (
    <div>
      <Grid container className={classes.container} direction={'row'} alignItems={'center'}>
        {/* <Grid> */}
        {/*  <img src={image} alt={title} className={classes.logo} /> */}
        {/* </Grid> */}
        <Grid className={classes.detailsContainer}>
          <Typography variant={'h3'} align={'center'}>
            <a href={link} className={classes.link} target={'blank'}>
              {title}
            </a>
          </Typography>
        </Grid>


      </Grid>
    </div>
  )
}

export default DocsItem
