import { css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import AppCreateButton from '../../apps/AppCreateButton'

function ConsoleNoApps() {
  return (
    <div
      css={css`
        margin-top: 30px;
      `}
    >
      <Typography variant={'h1'}>Hi there. To start using api you need to create app.</Typography>

      <Grid
        container
        justifyContent={'center'}
        css={css`
          margin-top: 80px;
        `}
      >
        <AppCreateButton />
      </Grid>
    </div>
  )
}

export default ConsoleNoApps
