import api from './api'
import { actions } from './reducer'

class Dispatcher {
  createPayment({ amount }: { amount: number }) {
    return async (dispatch) => {
      const data = await api.createPayment({ amount })
      console.log('payment data', data)
      // dispatch(actions.setActivePayment({ id: data.payment_id }))
      await dispatch(this.getPayment(data.payment_id))
      return data
    }
  }

  getPayment(id: string) {
    return async (dispatch) => {
      const payment = await api.getPayment(id)
      dispatch(actions.setActivePayment(payment))
      return payment
    }
  }
}

const WalletDispatcher = new Dispatcher()
export default WalletDispatcher
