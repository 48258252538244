import { css } from '@emotion/react'
import { Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../reducers'
import App from './app/App'
import PlanOverview from './planOverview/PlanOverview'
import ConsoleBanners from './console/ConsoleBanners'

function ConsolePage() {
  const { activePayment, apps } = useAppSelector((state) => ({
    activePayment: state.wallet.activePayment,
    apps: state.apps.list,
  }))

  // const handleCreatePayment = () => {
  //   dispatch(WalletDispatcher.createPayment({ amount: 4.99 }))
  // }

  const app = apps[0]
  if (!app) return null
  const { name } = app

  return (
    <Grid container>
      <Grid
        css={css`
          padding: 10px 0;
          margin: 10px 0;
          width: 100%;
        `}
      >
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Typography
            fontWeight={'bold'}
            css={css`
              font-size: 20px;
            `}
          >
            {name}
          </Typography>

          <div>
            <PlanOverview />
          </div>
        </Grid>

        <ConsoleBanners />
      </Grid>
      <Grid item flexGrow={1}>
        <Divider />
        <App app={app} />
      </Grid>
    </Grid>
  )
}

export default ConsolePage
