import makeStyles from '@mui/styles/makeStyles';
import FacebookIcon from '@mui/icons-material/Facebook'
import * as firebase from 'firebase/app'
import React from 'react'
import LoginButton from './LoginButton'

const useStyles = makeStyles(theme => ({
  icon: {
    color: '#fff',
    fontSize: '1rem',
  },

  button: {
    background: '#435aff',

    '&:hover': {
      backgroundColor: '#709aff',
    },
  },
}))

const loginWithFacebook = async () => {
  await firebase.auth().signOut()
  const provider = new firebase.auth.FacebookAuthProvider()
  await firebase.auth().signInWithPopup(provider)
}

function ButtonFacebook() {
  const classes = useStyles()

  const handleClick = async () => {
    await loginWithFacebook()
  }

  return (
    <LoginButton
      title={'Login With Facebook'}
      icon={<FacebookIcon className={classes.icon} />}
      onClick={handleClick}
      className={classes.button}
    />
  )
}

export default ButtonFacebook
