import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import LinkAuto from '../../../components/LinkAuto'

const useStyles = makeStyles({
  container: {
    borderRadius: 8,
    marginTop: 5,
    marginBottom: 5,
    padding: '5px 10px',
  },

  logo: {
    width: 70,
    height: 50,
    objectFit: 'contain',
    objectPosition: 'center',
  },

  detailsContainer: {
    marginTop: 0,
    marginLeft: 30,
    flexGrow: 1,
  },
})

interface Props {
  image: string
  title: string
  href: string
}

function OurServices(props: Props) {
  const classes = useStyles()
  const { image, href, title } = props

  return (
    <LinkAuto to={href}>
      <Grid container className={classes.container} alignItems={'center'}>
        <Grid>
          <img src={image} alt={title} className={classes.logo} />
        </Grid>
        <Grid className={classes.detailsContainer}>
          <Typography variant={'h3'}>{title}</Typography>
        </Grid>
        <Grid>{href && <FontAwesomeIcon icon={faChevronRight} />}</Grid>
      </Grid>
    </LinkAuto>
  )
}

export default OurServices
