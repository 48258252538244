import makeStyles from '@mui/styles/makeStyles'
import AuthDispatcher from '@reducers/auth/dispatcher'
import classNames from 'classnames'
import React, { ReactNode, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#435aff',
    border: 'none',
    padding: '0.6rem 1rem',
    borderRadius: 10,
    width: '100%',
    marginBottom: 10,
    cursor: 'pointer',

    '&:focus': {
      border: 'none',
    },

    '&:active': {
      border: 'none',
    },

    '&:disabled': {
      backgroundColor: '#cecece !important',
    },
  },

  iconContainer: {
    width: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  text: {
    fontSize: '1rem',
    color: '#fff',
    flexGrow: 1,
    userSelect: 'none',
  },

  icon: {
    color: '#fff',
    fontSize: '1rem',
  },
}))

interface Props {
  title: string
  className?: string
  icon: ReactNode
  onClick(): Promise<any>
}

function LoginButton(props: Props) {
  const { icon, title, onClick, className } = props
  const classes = useStyles()

  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch<ReduxDispatch>()
  const navigate = useNavigate()

  const handleClick = async (event) => {
    if (isLoading) return null
    setLoading(true)

    try {
      await onClick()
      await dispatch(AuthDispatcher.loginWithFirebaseToken())
      navigate('/console')
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  return (
    <button className={classNames(classes.container, className)} onClick={handleClick} disabled={isLoading}>
      <div className={classes.iconContainer}>{icon}</div>
      <span className={classes.text}>{title}</span>
    </button>
  )
}

export default LoginButton
