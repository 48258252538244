import AuthDispatcher from '../auth/dispatcher'
import api from './api'
import { actions } from './reducer'

class Dispatcher {
  getMe() {
    return async (dispatch) => {
      try {
        const user = await api.getMe()
        if (user) {
          dispatch(actions.set(user))
        }
      } catch (e) {
        const status = e?.response?.status
        if (status === 401) {
          dispatch(AuthDispatcher.logout())
        }
      }
    }
  }
}

const MeDispatcher = new Dispatcher()
export default MeDispatcher
