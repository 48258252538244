import { Container, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash'
import React from 'react'
import services from '../../const/services'
import DocsItem from './DocsItem'

const useStyles = makeStyles({
  container: {
    minHeight: '500px',
  },

  itemsContainer: {
    marginTop: 40,
  },

  title: {
    marginTop: '2rem',
    marginBottom: '1rem',
  },

  text: {
    width: '30rem',
    marginBottom: '1rem',
  },
  link: {
    fontWeight: 'bold',
  },
})

function SportList() {
  const classes = useStyles()

  return (
    <div>
      <Container>
        <Grid container direction={'column'} className={classes.container} alignItems={'center'}>
          <Typography variant={'h1'} align={'center'} className={classes.title}>
            Documentation
          </Typography>

          <Typography variant={'body1'} className={classes.text} align={'center'}>
            We share api that could be most useful for you. If you have any questions or suggestions, please write to
            our contact email.
          </Typography>

          <div>
            <a
              href="https://github.com/BettingApi/docs#readme"
              target={'_blank'}
              rel={'noreferrer'}
              className={classes.link}
            >
              Authorization
            </a>
          </div>

          <div className={classes.itemsContainer}>
            <Typography variant={'h3'} className={classes.text} align={'center'}>
              Services:
            </Typography>
            <Grid container justifyContent={'center'} alignItems={'center'}>
              <div>
                {_.map(services, service => (
                  <DocsItem title={service.name} link={service.docs_href} key={service.name} />
                ))}
              </div>
            </Grid>
          </div>
        </Grid>
      </Container>
    </div>
  );
}

export default SportList
