import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Divider, Grid, Typography } from '@mui/material'
import React, { ReactNode, useMemo } from 'react'

const StyledItemContainerDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`

interface Props {
  plan: any
  isSelected?: boolean
  children?: ReactNode
}
function PlanView(props: Props) {
  const { plan, isSelected, children } = props
  const { name, key, request_limit, price, price_y } = plan

  const priceFmt = useMemo(() => {
    if (!price) return null
    return `$${price}`
  }, [price])

  const rateLimitFmt = useMemo(() => {
    return new Intl.NumberFormat('en', { maximumSignificantDigits: 3 }).format(request_limit)
  }, [request_limit])

  return (
    <Grid
      css={css`
        background: #fff;
        border-radius: 15px;
        padding: 20px;
        width: 250px;
        box-shadow: rgba(33, 33, 33, 0.15) 0 0 5px 1px;
        margin-bottom: 30px;

        border: 1px solid transparent;

        ${isSelected && `border-color: #558ab9;`}
      `}
    >
      <StyledItemContainerDiv>
        <Typography variant={'h2'} align={'center'}>
          {name}
        </Typography>
      </StyledItemContainerDiv>

      <Divider css={css({ backgroundColor: '#333' })} />

      <StyledItemContainerDiv>
        <Typography variant={'body1'} align={'center'}>
          {rateLimitFmt} requests / hour
        </Typography>
      </StyledItemContainerDiv>

      <Divider />

      <StyledItemContainerDiv>
        <Typography variant={'body1'} align={'center'}>
          All bookmakers
        </Typography>
      </StyledItemContainerDiv>

      <Divider />

      <StyledItemContainerDiv>
        <Typography variant={'body1'} align={'center'}>
          All Sports
        </Typography>
      </StyledItemContainerDiv>

      <Divider />

      <div>
        {priceFmt ? (
          <Typography
            css={css`
              font-size: 20px;
              text-align: center;
              margin: 5px;
              color: #12285c;
            `}
          >
            {priceFmt}
            <span
              css={css`
                display: inline-block;
                color: #494141;
                font-size: 12px;
                margin-left: 5px;
              `}
            >
              / month
            </span>
          </Typography>
        ) : (
          <Typography
            css={css`
              font-size: 20px;
              text-align: center;
              margin: 5px;
              color: #12285c;
            `}
          >
            -
          </Typography>
        )}
      </div>

      {children}
    </Grid>
  )
}

export default PlanView
