import { initializeApp } from 'firebase/app'

let firebaseConfig = {
  apiKey: 'AIzaSyB5dzU4UCVvpgTYFwttxBtgbyfZFNOAtjM',
  authDomain: 'betting-api-com.firebaseapp.com',
  databaseURL: 'https://betting-api-com.firebaseio.com',
  projectId: 'betting-api-com',
  storageBucket: 'betting-api-com.appspot.com',
  messagingSenderId: '200153226160',
  appId: '1:200153226160:web:e9fd58bf620a9564df5475',
  measurementId: 'G-KFWB2PBVBH',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
