import { ReduxState } from '@reducers/index'
import React from 'react'
import { connect } from 'react-redux'
import { WithRouterProps } from 'react-router'
import AppDispatcher from './reducers/app/dispatcher'
import { ReduxDispatch } from './typings/ReduxDispatch'
import { ExtractConnectType } from './typings/ReduxExtractor'

interface State {
  isReady: boolean
}
class AppLauncher extends React.PureComponent<WithRouterProps<{}> & ExtractConnectType<typeof connectStore>, State> {
  state = {
    isReady: false,
  }

  render() {
    if (!this.state.isReady) return null
    const { children } = this.props
    return children
  }

  async handleInstall() {
    await this.props.install()
    this.setState({ isReady: true })
  }

  componentDidMount(): void {
    this.handleInstall()
  }
}

const connectStore = connect(
  (state: ReduxState) => ({
    auth: state.auth,
  }),
  (dispatch: ReduxDispatch) => ({
    install: () => dispatch(AppDispatcher.install()),
  }),
)
export default connectStore(AppLauncher)
