import { Grid, Link, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import ksIcon from './assets/langs/js.svg'

const useStyles = makeStyles({
  container: {
    marginTop: 40,
    paddingBottom: 20,
  },

  text: {
    marginBottom: '1rem',
  },

  icon: {
    width: 60,
    height: 60,
    resizeMode: 'contain',
    marginRight: 10,
  },
})

function ApiClients() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography variant={'h3'} className={classes.text} align={'center'}>
        Api Clients:
      </Typography>
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <div>
          <Grid container alignItems={'center'}>
            <img src={ksIcon} className={classes.icon} />
            <div>
              <span>JavaScript:</span>
              <div>
                <Link href={'https://github.com/BettingApi/js-rest-client'} target={'_blank'}>
                  https://github.com/BettingApi/js-rest-client
                </Link>
              </div>
            </div>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}

export default ApiClients
