import { Container, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import bgImage from './assets/background.jpg'

const useStyles = makeStyles({
  bg: {
    background: `url("${bgImage}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'none',
  },
  shadow: {
    backgroundColor: 'rgba(50, 50, 50, 0.6)',
  },
  container: {
    minHeight: '16rem',
    // paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  free: {
    color: '#0caa14',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  title: {
    color: '#fff',
  },
  text: {
    color: '#fff',
    lineHeight: '2rem',
  },
})

function SportList() {
  const classes = useStyles()

  return (
    <div className={classes.bg}>
      <div className={classes.shadow}>
        <Container>
          <Grid container direction={'column'} justifyContent={'space-between'} className={classes.container}>
            <Typography variant="h1" hidden className={classes.title}>
              Betting api for live odds, prematch odds from multiple bookmakers.
            </Typography>
            <Typography variant={'h2'} align={'center'} className={classes.title}>
              About
            </Typography>

            <Typography variant={'body1'} className={classes.text} align={'center'}>
              We provide and connect sport/betting services to make developer life easier. <br />
              {/* This project under personal use licence. And it is <span className={classes.free}>free</span>. */}
              You can check our docs to check if it can be useful for you.
              <br />
              If you want to get access or ask us something, please write to our email
              {/* If someone will find it useful, we may start commercial support. */}
            </Typography>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default SportList
