import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

const useStyles = makeStyles({
  linkIcon: {
    marginLeft: 5,
    color: '#4c4c4c',
  },
})

interface Props {
  title: string
  href: string
}

function DocsLink(props: Props) {
  const classes = useStyles()
  const { title, href } = props

  return (
    <Grid container justifyContent={'center'}>
      <Link target={'_blank'} href={href}>
        {title}
        <FontAwesomeIcon icon={faExternalLinkAlt} className={classes.linkIcon} />
      </Link>
    </Grid>
  );
}

export default DocsLink
