import { Button, css, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import PlanDispatcher from '@reducers/plans/dispatcher'
import { useAppDispatch, useAppSelector } from '@reducers/store'
import invariant from 'invariant'
import moment from 'moment'
import React from 'react'
import { useAsyncHandler } from 'react-hooks-async-handlers'
import { useNavigate } from 'react-router-dom'

function ExpirationBanner() {
  const { workspace } = useAppSelector((state) => ({ workspace: state.workspace }))
  const { plan_expired_at, plan } = workspace || {}
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const actionPurchaseRequest = useAsyncHandler(async () => {
    invariant(plan, 'invalid plan')
    await dispatch(PlanDispatcher.purchase({ key: plan }))
    navigate('/console/payment')
  })

  const nowMoment = moment()
  if (!plan_expired_at) return null

  const expireMoment = moment(plan_expired_at)
  const diffDays = expireMoment.diff(nowMoment, 'days')

  let text = ''
  let bannerBgColor = '#efcab5'
  if (diffDays < 7) {
    let dayFmt = diffDays ? `${diffDays} days` : 'today'
    text = `Your plan will expire in ${dayFmt}. Don' forget to continue subscription.`
    bannerBgColor = ' #efcab5'
  }

  if (diffDays < 0) {
    text = 'Your plan is expired and will be deactivated soon.'
    bannerBgColor = '#efcab5'
  }

  const handlePressPayment = () => {
    actionPurchaseRequest.execute()
  }

  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      css={css`
        margin-top: 10px;
        background: ${bannerBgColor};
        padding: 7px 15px;
        border-radius: 16px;
      `}
    >
      <Typography
        css={css`
          font-size: 14px;
        `}
      >
        {text}
      </Typography>

      <div>
        <Button size={'small'} variant={'text'} onClick={handlePressPayment}>
          Proceed to payment
        </Button>
      </div>
    </Grid>
  )
}

export default ExpirationBanner
