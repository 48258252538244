import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import Footer from '../../components/Footer'
import HeaderBar from '../../components/HeaderBar'
import About from './about/About'
import Contacts from './components/Contacts'
import OurServices from './ourServices/OurServices'

function Home() {
  const location = useLocation()

  useEffect(() => {
    if (location.hash.includes('#contacts')) {
      const elem = document.getElementById('scroller')
      if (!elem) return
      elem.scroll({ top: 1000, behavior: 'smooth' })
    }
  }, [])

  return (
    <div>
      <Helmet>
        <title>Betting Api. Live odds api. Bookmakers bets api.</title>
        <meta
          name="description"
          content="Live and prematch odds from bookmakers websites. Live and prematch bets api."
        />
      </Helmet>
      <HeaderBar />
      <About />
      {/* <SportList /> */}
      <OurServices />
      <Contacts />
      <Footer />
    </div>
  )
}

export default Home
