import { Container, Typography, css } from '@mui/material'
import Grid from '@mui/material/Grid'
import PlanDispatcher from '@reducers/plans/dispatcher'
import { useAppDispatch, useAppSelector } from '@reducers/store'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import RendererStatusSplit from 'react-renderer-status-split'
import HeaderBar from '../../components/HeaderBar'
import PlanView from '../console/plan/PlanView'

function Pricing() {
  const dispatch = useAppDispatch()
  const actionFetch = useAsyncFetch(async () => {
    await dispatch(PlanDispatcher.getAll())
  })

  const { plans } = useAppSelector((state) => ({
    plans: state.plans.list,
  }))

  return (
    <div>
      <Helmet>
        <title>Betting Api Pricing</title>
        <meta
          name="description"
          content="Pricing info about betting-api.com service. Try free trial or choose your personal plan"
        />
      </Helmet>
      <HeaderBar />

      <Container>
        <Typography
          variant={'h1'}
          css={css`
            margin-bottom: 20px;
            margin-top: 50px;
          `}
        >
          Available plans
        </Typography>

        <div>
          <RendererStatusSplit
            statuses={actionFetch}
            render={() => (
              <div>
                <Grid container gap={'30px'}>
                  {_.map(plans, (plan) => (
                    <PlanView plan={plan} />
                  ))}
                </Grid>
              </div>
            )}
          />
        </div>

        <div
          css={css`
            margin: 20px 0;
          `}
        >
          <Typography
            css={css`
              color: #6e6565;
            `}
          >
            Note: At the moment we support only crypto payments.
          </Typography>
        </div>
      </Container>
    </div>
  )
}

export default Pricing
