import { css } from '@emotion/react'
import { Container, Grid } from '@mui/material'
import AppsDispatcher from '@reducers/apps/dispatcher'
import { ReduxState, useAppDispatch, useAppSelector } from '@reducers/index'
import _ from 'lodash'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import RendererStatusSplit from 'react-renderer-status-split'
import { Outlet } from 'react-router-dom'
import HeaderBar from '../../../components/HeaderBar'
import Loader from '../../../components/Loader'
import useIsLoggedIn from '../../../hooks/useIsLoggedIn'
import ConsoleNoApps from './components/ConsoleNoApps'

function ConsoleLayout() {
  const dispatch = useAppDispatch()
  const isLoggedIn = useIsLoggedIn()
  const { apps } = useAppSelector((state: ReduxState) => ({ apps: state.apps.list }))

  const fetchData = useAsyncFetch(async () => {
    if (!isLoggedIn) return null
    await dispatch(AppsDispatcher.getAll())
  }, [isLoggedIn])

  return (
    <div>
      <HeaderBar />

      <Container>
        <RendererStatusSplit
          statuses={fetchData}
          isEmpty={_.isEmpty(apps)}
          renderLoading={() => (
            <Grid
              container
              justifyContent={'center'}
              css={css`
                margin-top: 30px;
              `}
            >
              <Loader />
            </Grid>
          )}
          renderEmpty={() => <ConsoleNoApps />}
          render={() => <Outlet />}
        />
      </Container>
    </div>
  )
}

export default ConsoleLayout
