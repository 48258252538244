import PlanDispatcher from '@reducers/plans/dispatcher'
import { useAppDispatch } from '@reducers/store'
import WorkspaceDispatcher from '@reducers/workspace/dispatcher'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import RendererStatusSplit from 'react-renderer-status-split'
import Loader from '../../../components/Loader'
import WorkspacePlan from './WorkspacePlan'

function PlanOverview() {
  const dispatch = useAppDispatch()
  const actionFetch = useAsyncFetch(async () => {
    await dispatch(PlanDispatcher.getAll())
    await dispatch(WorkspaceDispatcher.getWorkspace())
  })

  return (
    <RendererStatusSplit statuses={actionFetch} renderLoading={() => <Loader />} render={() => <WorkspacePlan />} />
  )
}

export default PlanOverview
