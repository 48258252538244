import apiClient from '../../libs/apiClient'

export default {
  async getApps(): Promise<App> {
    const result = await apiClient.get(`/app/all`)
    return result.data
  },
  async createApp({ name }: { name: string }): Promise<AuthData> {
    const result = await apiClient.post(`/app/new`, { name })
    return result.data
  },
}
