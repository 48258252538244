import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  token?: string | null
}

const initialState: State = {
  token: '',
}

const slice = createSlice({
  name: 'AUTH',
  initialState,
  extraReducers: {
    [appTypes.RESET]: () => initialState,
  },
  reducers: {
    setToken(state, action: PayloadAction<Pick<State, 'token'>>) {
      const { token } = action.payload
      state.token = token
    },
  },
})

export const { reducer, actions } = slice
export default reducer
