import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LayoutRequireAuth from './layout/LayoutRequireAuth'
import XBet from './pages/apis/1xBet'
import BetWay from './pages/apis/BetWay'
import Fonbet from './pages/apis/Fonbet'
import GGBet from './pages/apis/GGBet'
import Leonbets from './pages/apis/Leonbets'
import MarathonBet from './pages/apis/MarathonBet'
import Parimatch from './pages/apis/Parimatch'
import Sbobet from './pages/apis/Sbobet'
import ConsolePage from './pages/console/ConsolePage'
import PaymentPage from './pages/console/PaymentPage'
import PlanPage from './pages/console/PlanPage'
import ConsoleLayout from './pages/console/layout/ConsoleLayout'
import Docs from './pages/docs/Docs'
import Home from './pages/home/Home'
import Pricing from './pages/pricing/Pricing'
import PolicyPage from './pages/system/PolicyPage'

function AppRouter() {
  return (
    <Routes>
      <Route path="/" index element={<Home />} />
      <Route path="/docs" element={<Docs />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/policy" element={<PolicyPage />} />

      <Route path="/parimatch" element={<Parimatch />} />
      <Route path="/marathonbet" element={<MarathonBet />} />
      <Route path="/1xbet" element={<XBet />} />
      <Route path="/sbobet" element={<Sbobet />} />
      <Route path="/fonbet" element={<Fonbet />} />
      <Route path="/ggbet" element={<GGBet />} />
      <Route path="/betway" element={<BetWay />} />
      <Route path="/leonbets" element={<Leonbets />} />

      <Route path="/console" element={<LayoutRequireAuth />}>
        <Route path={''} element={<ConsoleLayout />}>
          <Route path={''} element={<ConsolePage />} />
          <Route path={'/console/plan'} element={<PlanPage />} />
          <Route path={'/console/payment'} element={<PaymentPage />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRouter
