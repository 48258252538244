import appTypes from '@reducers/app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Payment } from './api'

interface State {
  activePayment?: Payment
}

const initialState: State = {}

const slice = createSlice({
  name: 'WALLET',
  initialState,
  extraReducers: {
    [appTypes.RESET]: () => initialState,
  },
  reducers: {
    setActivePayment(state, action: PayloadAction<State['activePayment']>) {
      const payment = action.payload
      if (payment) {
        state.activePayment = { ...state.activePayment, ...payment }
      } else {
        state.activePayment = undefined
      }
    },
  },
})

export const { reducer, actions } = slice
export default reducer
