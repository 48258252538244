import React from 'react'
import ExpirationBanner from './expiration/ExpirationBanner'

interface Props {}
function ConsoleBanners(props: Props) {
  return (
    <div>
      <ExpirationBanner />
    </div>
  )
}

export default ConsoleBanners
