import apiClient from '../../libs/apiClient'

export interface Payment {
  id: string
  tx_id: string
}

export default {
  async createPayment(body: { amount: number }): Promise<{ payment_id: string }> {
    const result = await apiClient.post(`/wallet/payment`, body)
    return result.data
  },

  async getPayment(id: string): Promise<Payment> {
    const result = await apiClient.get(`/wallet/payment/${id}`)
    return result.data
  },
}
