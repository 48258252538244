import { css } from '@emotion/react'
import { Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PaymentUI from '../../components/payment/PaymentUI'
import { useAppDispatch, useAppSelector } from '../../reducers'

function PaymentPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  console.log('PaymentPage render')
  const { activePayment } = useAppSelector((state) => ({
    activePayment: state.wallet.activePayment,
  }))

  useEffect(() => {
    if (!activePayment) {
      navigate('/console', { replace: true })
    }
  }, [activePayment])
  console.log('activePayment', activePayment)
  if (!activePayment) {
    return null
  }

  return (
    <Grid
      container
      flexDirection={'column'}
      css={css`
        height: calc(100vh - 64px);
        padding-bottom: 10px;
      `}
    >
      <div
        css={css`
          margin-top: 20px;
          margin-bottom: 30px;
        `}
      >
        <Typography
          variant={'h1'}
          css={css`
            margin-bottom: 10px;
          `}
        >
          Payment
        </Typography>
        <Typography>Once you finish, you can close this page. Payment will be credited during few minutes.</Typography>
      </div>
      <Grid flexGrow={1}>{activePayment && <PaymentUI payment={activePayment} />}</Grid>
    </Grid>
  )
}

export default PaymentPage
