import { faMinus, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

const useStyles = makeStyles({
  plus: {
    color: '#2faa24',
  },
})

interface Props {
  isSupported: boolean
}

function SupportCheckStatus(props: Props) {
  const { isSupported } = props
  const classes = useStyles()

  if (isSupported) return <FontAwesomeIcon icon={faCheck} className={classes.plus} />

  return <FontAwesomeIcon icon={faMinus} />
}

export default SupportCheckStatus
