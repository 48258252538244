import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash'
import React from 'react'
import SupportCheckStatus from './components/SupportCheckStatus'

const useStyles = makeStyles({
  table: {
    minWidth: 600,
  },

  tableHolder: {
    background: '#f7f7f7',
  },
  tableHead: {
    fontWeight: 'bold',
  },
})

interface Row {
  category: string
  hasLive: boolean
  hasLine: boolean
  hasEvents: boolean
}

const defaultCategories = ['Football', 'Tennis', 'Hockey', 'Basketball']

type Permission = 'live' | 'line' | 'events'

interface Props {
  football: Permission[]
}

function APISupportTable(props: Props) {
  const classes = useStyles()

  let categories = defaultCategories
  const rows: Row[] = _.map(categories, category => {
    const row: Row = { category, hasLive: false, hasLine: false, hasEvents: false }

    switch (category) {
      case 'Football': {
        const permissions = props.football
        row.hasLive = _.includes(permissions, 'live')
        row.hasLine = _.includes(permissions, 'line')
        row.hasEvents = _.includes(permissions, 'events')
        break
      }

      default:
    }

    return row
  })

  return (
    <TableContainer component={Paper} className={classes.tableHolder}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>Category</TableCell>
            <TableCell align="center" className={classes.tableHead}>
              Live Odds
            </TableCell>
            <TableCell align="center" className={classes.tableHead}>
              PreMatch Odds
            </TableCell>
            <TableCell align="center" className={classes.tableHead}>
              Subscriptions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.category}>
              <TableCell component="th" scope="row">
                {row.category}
              </TableCell>
              <TableCell align="center">
                <SupportCheckStatus isSupported={row.hasLive} />
              </TableCell>
              <TableCell align="center">
                <SupportCheckStatus isSupported={row.hasLine} />
              </TableCell>
              <TableCell align="center">
                <SupportCheckStatus isSupported={row.hasEvents} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default APISupportTable
