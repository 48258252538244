import { AppBar, Container, Grid, Toolbar, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ConsoleButton from './ConsoleButton'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: '#fff',
  },
  titleLink: {
    textDecoration: 'none',
  },
  bar: {
    backgroundColor: '#2b204e',
  },

  link: {
    color: '#fff',
    textDecoration: 'none',
    marginRight: '2rem',
    fontWeight: 100,

    '&:hover': {
      color: '#aa96d0',
    },
    '&:active': {
      color: '#aa96d0',
    },
  },
}))

function HeaderBar() {
  const classes = useStyles()

  const navigate = useNavigate()

  const handleClickContacts = (e) => {
    e.preventDefault()
    // $('#scroller').scrollTo({ y: 1000 })
    navigate('/#contacts')
    const elem = document.getElementById('scroller')
    if (!elem) return null
    elem.scroll({
      top: 1000,
      behavior: 'smooth',
    })
  }

  return (
    <AppBar position="static" color={'primary'} className={classes.bar}>
      <Toolbar>
        <Container>
          <Grid alignItems={'center'} justifyContent={'space-between'} container>
            {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"> */}
            {/*  <MenuIcon /> */}
            {/* </IconButton> */}
            <Link to={'/'} className={classes.titleLink}>
              <Typography variant="h1" component={'h2'} className={classes.title}>
                Betting Api
              </Typography>
            </Link>

            <div>
              <Grid container alignItems={'center'}>
                <Link to={'/pricing'} className={classes.link}>
                  Pricing
                </Link>

                <Link to={'/docs'} className={classes.link}>
                  Docs
                </Link>
                <Link to={'#'} className={classes.link} onClick={handleClickContacts}>
                  Contacts
                </Link>
                <ConsoleButton />
              </Grid>
            </div>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default HeaderBar
